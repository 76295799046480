<template>
  <nice-cascader
    :props="{ multiple: true, checkStrictly: true }"
    v-model="locationIds"
    :options="locations"
    :placeholder="$t('client.queries.form.locationPh')"
    :show-all-levels="false"
    :collapse-tags="false"
    clearable
    filterable
    :filter-method="filterLocations"
  />
</template>

<script>
import { defineComponent } from "vue"
import { getLocationGroups } from "@/utils/get-locations"

export default defineComponent({
  props: ["record", "field"],
  data() {
    return {
      locations: [],
    }
  },
  computed: {
    locationValues() {
      const formatLocation = (location, parentsLocationIds = []) => {
        const selfValue = parentsLocationIds.concat([location.value])
        const childrenValues = !location.children
          ? []
          : location.children.map(child => formatLocation(child, selfValue))

        return [selfValue].concat(_.flatten(childrenValues))
      }
      return _.flatten(
        this.locations.map(l => formatLocation(l)),
        1
      )
    },
    locationIds: {
      get() {
        return this.record.location_ids
          ? this.record.location_ids.map(id => {
              return this.locationValues.find(value => _.last(value) == id)
            })
          : null
      },
      set(value) {
        this.record.location_ids = value ? value.map(v => _.last(v)) : null
      },
    },
  },
  methods: {
    async getLocationGroups() {
      this.locations = await getLocationGroups(this.$db)
    },
    filterLocations(node, keyword) {
      return node.label.toLowerCase().includes(keyword.toLowerCase())
    },
  },
  mounted() {
    this.getLocationGroups()
  },
})
</script>

<style>
.ps-cascader .el-cascader-menu__wrap {
  height: initial;
  max-height: calc(100vh - 300px);
}
.box-cell .el-cascader__search-input {
  background: rgb(247, 247, 245);
  margin-left: 7px;
}
</style>
