<template>
  <div class="flex items-center w-full h-full">
    <number-field
      :placeholder="$t('client.queries.form.selectFieldBy')"
      :record="record"
      :field="field"
      :customFieldName="field.fieldName"
    />
    <span class="light mx-2">–</span>
    <number-field
      :placeholder="$t('client.queries.form.selectFieldTill')"
      :record="record"
      :field="field"
      :customFieldName="`${field.fieldName}_to`"
    />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import NumberField from "@/components/detail-view/NumberField.vue"

export default defineComponent({
  components: {
    NumberField,
  },
  props: {
    record: Object as PropType<any>,
    field: Object as PropType<any>,
  },
})
</script>
