<template>
  <nice-cascader
    :props="{ multiple: true }"
    class="w-full"
    v-model="record.rs_type_categories"
    :options="rsCategories"
    :placeholder="$t('client.queries.form.propertyTypePh')"
    collapse-tags
    clearable
  />
</template>

<script>
import { defineComponent } from "vue"
import { getPropertyOptionsMapping } from "@/utils/get-property-options-mapping"
import rsCategories from "@/config/property/rs-categories"

export default defineComponent({
  props: ["record", "field"],
  computed: {
    rsTypeCategories() {
      return getPropertyOptionsMapping(this.$db).getRsTypeCategories()
    },
    allowedRsTypeCategories() {
      const { allowedRsTypesInSavedQueries: allowedRsTypes, forbiddenRsCategories } = this.$db.shopData
      return this.rsTypeCategories
        .filter(
          o => allowedRsTypes.length <= 0 || allowedRsTypes.includes(o.value) || this.selectedRsTypes.includes(o.value)
        )
        .map(o => ({
          ...o,
          children: o.children.filter(
            c =>
              forbiddenRsCategories.length <= 0 ||
              !forbiddenRsCategories.includes(c.value) ||
              this.selectedRsCategories.includes(c.value)
          ),
        }))
    },
    rsCategories() {
      const ownCategories = this.$db.shopData.customCategories
        .filter(c => !this.record.marketing_type || c.objectType == this.record.marketing_type)
        .map(c => ({ ...c, label: c.name, value: c.id }))
      const categories = this.allowedRsTypeCategories.map(o => ({
        ...o,
        children:
          o.value == "TRADE_SITE" ? undefined : o.children.concat(ownCategories.filter(c => c.rsType == o.value)),
        disabled: this.selectedRsTypes.length > 0 && !this.selectedRsTypes.includes(o.value),
      }))

      return categories
    },
    selectedRsTypes() {
      return (this.record.rs_type_categories || []).map(o => o[0])
    },
    selectedRsCategories() {
      return (this.record.rs_type_categories || []).map(o => o[1])
    },
  },
})
</script>
