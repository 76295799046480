<template>
  <dv-row
    :theme="field.theme"
    :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`"
    :hint="field.hint"
    class="flex-col"
  >
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'w-full',
      ]"
    >
      <rich-textarea ref="richtext" v-model="record[field.fieldName]" :readonly="isReadOnly" />
      <footer v-if="snippets && snippets.length > 0 && !field.readonly" class="flex justify-end mt-1">
        <nice-select
          @change="snippetSelected"
          :options="snippets"
          size="small"
          :placeholder="$t('detailView.snippetPh')"
        />
      </footer>
    </div>
  </dv-row>
</template>

<script lang="ts">
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

export default {
  props: ["record", "field"],
  methods: {
    snippetSelected(val) {
      const found = this.snippets.find(o => o.id == val)

      if (found) {
        try {
          this.$refs.richtext.froalaEditor.html.insert(found.body)
        } catch (error) {
          this.$refs.richtext.froalaEditor.html.set(found.body)
        }
      }
    },
  },
  computed: {
    isReadOnly() {
      return coerceBooleanAttribute(!!this.field.readonly)
    },
    snippets() {
      if (this.field.formField.snippets) {
        return this.$db.shopData.textSnippets.filter(
          o => o.category == this.field.formField.snippets && o.role != "short"
        )
      }
    },
  },
}
</script>

<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
