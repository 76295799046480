<template>
  <div>
    <dv-row :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`" :hint="field.hint" :field="field">
      <nice-select
        :disabled="field.readonly"
        v-model="record.marketing_type"
        :options="marketingTypes"
        :placeholder="$t('detailView.categoryMarketing')"
        :clearable="false"
        class="mr-2 rounded"
        :class="{
          'disabled-visibility-category': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.marketing_type && field.submitAttempt,
        }"
        :style="`background-color: ${field.color}`"
      />
      <nice-select
        :disabled="field.readonly"
        v-model="record.object_type"
        :options="objectTypes"
        :placeholder="$t('detailView.categoryType')"
        class="rounded"
        :class="{
          'disabled-visibility-category': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.object_type && field.submitAttempt,
        }"
        :style="`background-color: ${field.color}`"
      />
    </dv-row>
    <dv-row
      :label="`${$t('detailView.categoryField')} ${field.mandatory ? '*' : ''}`"
      :hint="field.hint"
      :field="field"
    >
      <nice-select
        :disabled="field.readonly"
        v-model="record.rs_type"
        :options="rsTypes"
        :placeholder="$t('detailView.categoryProptype')"
        class="mr-2 rounded"
        :class="{
          disabled: rsTypes.length <= 0,
          'disabled-visibility-category': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.rs_type && field.submitAttempt,
        }"
        :style="`background-color: ${field.color}`"
      />
      <div class="w-100">
        <nice-select
          v-if="record.rs_type != 'TRADE_SITE'"
          :disabled="field.readonly"
          v-model="record.rs_category"
          :options="rsCategories"
          :placeholder="$t('detailView.categoryCat')"
          class="rounded"
          :class="{
            disabled: rsCategories.length <= 0,
            'disabled-visibility-category': field.readonly,
            'mandatory-outline': field.mandatory && !field.readonly && !record.rs_category && field.submitAttempt,
          }"
          :style="`background-color: ${field.color}`"
        />
      </div>
    </dv-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { BaseCategory } from "@/interfaces"

const ALLOWED_RS_TYPES = {
  LIVING: ["APARTMENT", "HOUSE", "TRADE_SITE", "GARAGE", "SHORT_TERM_ACCOMODATION"],
  COMMERCIAL: ["OFFICE", "GASTRONOMY", "INDUSTRY", "STORE", "TRADE_SITE", "SPECIAL_PURPOSE"],
  INVESTMENT: ["INVESTMENT"],
}

const CATEGORY_MAPPING = {
  APARTMENT: "apartment_type",
  HOUSE: "building_type",
  // TRADE_SITE: "apartment_type",
  GARAGE: "garage_type",
  SHORT_TERM_ACCOMODATION: "short_term_accomodation_type",
  OFFICE: "office_type",
  GASTRONOMY: "gastronomy_type",
  INDUSTRY: "industry_type",
  STORE: "store_type",
  SPECIAL_PURPOSE: "special_purpose_type",
  INVESTMENT: "investment_type",
}

export default defineComponent({
  props: ["record", "field"],

  watch: {
    "record.marketing_type": {
      handler(newValue) {
        if (newValue == "RENT" && this.record.object_type == "INVESTMENT") {
          this.record.object_type = null
        }
      },
    },
    "record.object_type": {
      handler() {
        this.record.rs_type = null
      },
    },
    "record.rs_type": {
      handler() {
        this.record.rs_category = null
      },
    },
  },

  computed: {
    marketingTypes(): BaseCategory[] {
      return this.$db.shopData.propertyOptionsMapping.marketing_type
    },
    objectTypes(): BaseCategory[] {
      const objectTypes = this.$db.shopData.propertyOptionsMapping.object_type
      if (this.record.marketing_type == "RENT") {
        return Object.keys(objectTypes)
          .filter(key => key != "INVESTMENT")
          .map(key => ({
            id: key,
            name: objectTypes[key],
          }))
      }

      return objectTypes
    },
    rsTypes(): BaseCategory[] {
      const rsTypes = this.$db.shopData.propertyOptionsMapping.rs_type
      const allowedRsTypes = ALLOWED_RS_TYPES[this.record.object_type]
      if (!allowedRsTypes) return []

      return Object.keys(rsTypes)
        .filter(key => allowedRsTypes.includes(key))
        .map(key => ({
          id: key,
          name: rsTypes[key],
        }))
    },
    rsCategoryKey(): string | null {
      return CATEGORY_MAPPING[this.record.rs_type]
    },
    rsCategories(): BaseCategory[] {
      if (!this.rsCategoryKey) return []
      const ownCategories = this.$db.shopData.customCategories.filter(c => c.rsType == this.record.rs_type)
      const categories = this.$db.shopData.propertyOptionsMapping[this.rsCategoryKey]
      return { ...categories, ...ownCategories.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {}) }
    },
  },
})
</script>

<style>
.disabled-visibility-category > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.55 !important;
}

.disabled-visibility-category > .el-input > span {
  display: none !important;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
