<template>
  <div v-redacted>
    <dv-row
      :theme="field.theme"
      :hint="field.hint"
      :field="field"
      :copy-value="`${record[streetKey]} ${record[houseNumberKey]}, ${record[zipCodeKey]} ${record[cityKey]}`"
      :copy-tooltip="$t('detailView.addressCopy')"
    >
      <template v-slot:label>
        <span v-if="hasCustomLabel" v-html="field.formField.label"></span>
        <span v-else>Straße + Nr</span>
        <span v-if="field.mandatory">*</span>
        <a
          v-if="address"
          :href="gmapsURL"
          target="_blank"
          tabindex="-1"
          class="light ml-1"
          :title="$t('detailView.addressLink')"
        >
          <fa-icon name="external-link" />
        </a>
      </template>
      <div class="rounded el-input" style="margin-right: 5px">
        <input
          ref="street"
          type="text"
          :placeholder="$t('detailView.addressStreet')"
          v-model="record[streetKey]"
          class="mr-2 input"
          :readonly="field.readonly"
          :class="{
            'disabled-visibility-address': field.readonly,
            'mandatory-outline': field.mandatory && !field.readonly && !record[streetKey] && field.submitAttempt,
          }"
        />
      </div>
      <input
        type="text"
        :placeholder="$t('detailView.addressNumber')"
        v-model="record[houseNumberKey]"
        style="width: 70px"
        class="rounded"
        :readonly="field.readonly"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record[houseNumberKey] && field.submitAttempt,
        }"
      />
    </dv-row>
    <dv-row
      :theme="field.theme"
      :label="`${hasCustomLabel ? '' : $t('detailView.addressAll')} ${field.mandatory ? '*' : ''}`"
      :field="field"
    >
      <input
        :readonly="field.readonly"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record[zipCodeKey] && field.submitAttempt,
        }"
        type="text"
        :placeholder="$t('detailView.addressZipcode')"
        v-model="record[zipCodeKey]"
        class="mr-2 rounded"
      />
      <input
        :readonly="field.readonly"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record[cityKey] && field.submitAttempt,
        }"
        type="text"
        :placeholder="$t('detailView.addressCity')"
        v-model="record[cityKey]"
        class="mr-2 rounded"
      />
      <input
        :readonly="field.readonly"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record[countryKey] && field.submitAttempt,
        }"
        type="text"
        class="rounded"
        :placeholder="$t('detailView.addressCountry')"
        v-model="record[countryKey]"
      />
    </dv-row>
  </div>
</template>

<script>
export default {
  props: ["record", "field"],

  computed: {
    hasCustomLabel() {
      return (
        this.field.formField.label != this.$t("detailView.address") &&
        this.field.formField.label != this.$t("detailView.address") + " 2"
      )
    },
    streetKey() {
      return `${this.field.formField.prefix}street`
    },
    houseNumberKey() {
      return `${this.field.formField.prefix}house_number`
    },
    zipCodeKey() {
      return `${this.field.formField.prefix}zip_code`
    },
    cityKey() {
      return `${this.field.formField.prefix}city`
    },
    countryKey() {
      return `${this.field.formField.prefix}country`
    },
    address() {
      const street = [this.record[this.streetKey], this.record[this.houseNumberKey]].join(" ")

      return [street, [this.record[this.zipCodeKey], this.record[this.cityKey], this.record[this.countryKey]].join(" ")]
        .filter(o => o.trim())
        .join(", ")
    },
    gmapsURL() {
      return `https://maps.google.de/maps?hl=de&q=${this.address}`
    },
  },

  mounted() {
    if (!this.field.readonly) {
      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.street, {
        types: ["address"],
      })
    }

    const placesNamesMapping = {
      route: this.streetKey,
      street_number: this.houseNumberKey,
      postal_code: this.zipCodeKey,
      locality: this.cityKey,
      country: this.countryKey,
    }

    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace()

      place.address_components.forEach(component => {
        const type = component.types[0]
        if (placesNamesMapping[type]) {
          this.record[placesNamesMapping[type]] = component.long_name
        }
        // console.log(`component ${type}`, component);
      })
    })
  },
}
</script>

<style>
.disabled-visibility-address > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}

.disabled-visibility-address {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}

.disabled-visibility-address > .el-input > span {
  display: none !important;
}
</style>
