<template>
  <div>
    <dv-row
      :hint="field.hint"
      :copy-value="`${record.street} ${record.house_number}, ${record.zip_code} ${record.city}`"
      :copy-tooltip="$t('detailView.addressCopy')"
    >
      <template v-slot:label>
        <span v-if="hasCustomLabel" v-html="field.formField.label"></span>
        <span v-else>{{ $t("detailView.addressStreet") + " + " + $t("detailView.addressNumber") }}</span>
        <span v-if="field.mandatory">*</span>
        <a
          v-if="address"
          :href="gmapsURL"
          target="_blank"
          tabindex="-1"
          class="light ml-1"
          :title="$t('detailView.addressLink')"
        >
          <fa-icon name="external-link" />
        </a>
      </template>
      <input
        :readonly="isReadOnly"
        ref="street"
        type="text"
        :placeholder="$t('detailView.addressStreet')"
        v-model="record.street"
        class="mr-2"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.street && field.submitAttempt,
        }"
      />
      <input
        :readonly="isReadOnly"
        type="text"
        :placeholder="$t('detailView.addressNumber')"
        v-model="record.house_number"
        style="width: 70px"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.house_number && field.submitAttempt,
        }"
      />
    </dv-row>
    <dv-row
      :label="`${$t('detailView.addressZipcode')} / ${$t('detailView.addressCity')} ${field.mandatory ? '*' : ''}`"
    >
      <input
        :readonly="isReadOnly"
        type="text"
        :placeholder="$t('detailView.addressZipcode')"
        v-model="record.zip_code"
        class="mr-2"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.zip_code && field.submitAttempt,
        }"
      />
      <input
        ref="cityInput"
        :readonly="isReadOnly"
        type="text"
        :placeholder="$t('detailView.addressCity')"
        v-model="record.city"
        :class="{
          'disabled-visibility-address': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.city && field.submitAttempt,
        }"
      />
    </dv-row>
    <dv-row
      :label="`${$t('detailView.addressRegion')} / ${$t('detailView.addressCountry')} ${field.mandatory ? '*' : ''}`"
      :field="field"
    >
      <nice-select
        :disabled="field.readonly"
        v-model="record.region"
        :options="regions"
        :placeholder="$t('detailView.addressRegion')"
        class="mr-2"
        :class="{
          'disabled-visibility-address-select': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.region && field.submitAttempt,
        }"
      />
      <nice-select
        :disabled="field.readonly"
        v-model="record.country"
        :options="countries"
        :placeholder="$t('detailView.addressCountry')"
        :class="{
          'disabled-visibility-address-select': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.country && field.submitAttempt,
        }"
        @change="record.region = null"
      />
    </dv-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { countriesByAlpha3 } from "@/config/client/countries"
import { alpha2ToAlpha3 } from "i18n-iso-countries"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

export default defineComponent({
  props: ["record", "field"],
  data() {
    return {
      regions: [] as any,
    }
  },
  watch: {
    "record.country": {
      handler() {
        this.fetchRegions()
      },
    },
  },

  computed: {
    isReadOnly() {
      return coerceBooleanAttribute(!!this.field.readonly)
    },
    hasCustomLabel(): boolean {
      return (
        this.field.formField.label != this.$t("detailView.address") &&
        this.field.formField.label != this.$t("detailView.address") + " 2"
      )
    },
    address(): string {
      const street = [this.record.street, this.record.house_number].join(" ")

      return [street, [this.record.zip_code, this.record.city, this.record.country].join(" ")]
        .filter(o => o.trim())
        .join(", ")
    },
    gmapsURL(): string {
      return `https://maps.google.de/maps?hl=de&q=${this.address}`
    },
    countries(): any {
      return countriesByAlpha3(this.$root.$i18n.locale)
    },
    googleCountryRestriction(): any {
      const countryMapping = {
        swiss: "ch",
        austria: "at",
        spain: "es",
        hungary: "hu",
      }
      return this.$db.shopData.sellsInternationally
        ? null
        : this.$db.shopData.internationalCountry
        ? countryMapping[this.$db.shopData.internationalCountry]
        : "de"
    },
  },

  methods: {
    fetchRegions() {
      if (!this.record.country) {
        this.regions = []
        return
      }

      this.$axios
        .get(`/services/addresses/regions?country=${this.record.country}`)
        .then(({ data: regions }: { data: string[] }) => {
          this.regions = regions.map(region => ({ id: region, name: region }))
        })
        .catch(err => {
          App.alert(this.$t("detailView.addressError"))
          console.log(err)
        })
    },
    setAutocomplete() {
      if (!window?.google?.maps?.places) {
        console.warn("Google Maps Places API not loaded, please reload the page")
        return
      }
      const autocomplete = new (window as any).google.maps.places.Autocomplete(this.$refs.street, {
        types: ["address"],
      })

      const placesNamesMapping = {
        route: "street",
        street_number: "house_number",
        postal_code: "zip_code",
        locality: "city",
      }

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace()

        place.address_components.forEach(component => {
          const type = component.types[0]
          if (placesNamesMapping[type]) {
            this.record[placesNamesMapping[type]] = component.long_name
          }
        })

        const countryComponent = place.address_components.find(c => c.types.includes("country"))
        if (countryComponent) this.record.country = alpha2ToAlpha3(countryComponent.short_name)

        const regionComponent = place.address_components.find(c => c.types.includes("administrative_area_level_1"))
        if (regionComponent) this.record.region = regionComponent.long_name

        this.record.lat = place.geometry.location.lat()
        this.record.lng = place.geometry.location.lng()
      })
    },
    setCityAutocomplete() {
      if (!window?.google?.maps?.places) {
        console.warn("Google Maps Places API not loaded, please reload the page")
        return
      }
      const autocomplete = new (window as any).google.maps.places.Autocomplete(this.$refs.cityInput, {
        types: ["(cities)"],
        componentRestrictions: !!this.googleCountryRestriction ? { country: this.googleCountryRestriction } : undefined,
      })
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace()
        if (!place.place_id) return // user wrote random shit
        const countryComponent = place.address_components.find(c => c.types.includes("country"))
        if (countryComponent) this.record.country = alpha2ToAlpha3(countryComponent.short_name)

        const regionComponent = place.address_components.find(c => c.types.includes("administrative_area_level_1"))
        if (regionComponent) this.record.region = regionComponent.long_name
        this.record.city = place.name
      })
    },
  },

  mounted() {
    if (this.field.readonly) return
    this.fetchRegions()
    this.setAutocomplete()
    this.setCityAutocomplete()
  },
})
</script>

<style>
.disabled-visibility-address > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.55 !important;
}

.disabled-visibility-address {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.55 !important;
}

.disabled-visibility-address > .el-input > span {
  display: none !important;
}
.disabled-visibility-address-select > .el-input > span {
  display: none !important;
  background-color: rgb(245, 245, 245) !important;
}
.disabled-visibility-address-select > .el-input > input {
  color: black !important;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
