<template>
  <polygon-picker
    v-model="record.geometry"
    :color="$db.shopData.color"
    :international-country="$db.shopData.internationalCountry"
    :international="$db.shopData.sellsInternationally"
    :hint="$t('client.queries.form.locationHint')"
  />
</template>

<script>
import { defineComponent } from "vue"
import PolygonPicker from "@/components/gmaps/polygon-picker.vue"

export default defineComponent({
  props: ["record", "field"],
  components: {
    PolygonPicker,
  },
})
</script>