<template>
  <div>
    <card
      v-for="(child, $idx) in record.children"
      :key="$idx"
      class="pt-3 pb-1 px-3 mt-2"
      :class="{ hidden: child._destroy }"
    >
      <child
        :child="child"
        :groups="groups"
        @upgrade="upgradeChild(child)"
        @delete="removeChild($idx)"
        :formFields="formFields"
        :readonly="isReadOnly"
      />
    </card>
    <dv-row v-if="!field.readonly" :theme="field.theme" class="mt-2">
      <template v-slot:label>{{ field.formField.label }} {{ field.mandatory ? "*" : "" }}</template>
      <p class="mt-1">
        <a href="#" class="text-muted" @click.prevent="addNewChild">
          <fa-icon name="plus" class="mr-1" />
          <span>{{ $t("detailView.childrenAdd") }}</span>
        </a>
      </p>
    </dv-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import FormFields from "@/config/client/form-fields"
import detailViewForm from "@/config/detail-view-form"
import { DEFAULT_CHILDREN_FIELDS } from "@/config/client/default-client-fields"
import { Group, DetailViewGroup, FormField } from "@/interfaces"
import Child from "./ChildrenFieldEl.vue"
import { snakeCase } from "@/utils/with-case"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

const BASE_CHILD = {
  salutation: "mr",
  first_name: null,
  last_name: null,
  emails: [],
  client_addresses: [],
}

export default defineComponent({
  props: ["record", "field"],
  components: { Child },
  methods: {
    addNewChild() {
      this.record.children.push(JSON.parse(JSON.stringify(BASE_CHILD)))
    },
    removeChild(idx) {
      this.record.children[idx]["_destroy"] = true
    },
    upgradeChild(child) {
      this.$warn(
        {
          title: this.$t("detailView.childrenUpgrade"),
          desc: this.$t("detailView.childrenDelConfirm"),
          confirmButtonText: this.$t("detailView.childrenDelBtn"),
          redButton: true,
        },
        () => {
          this.$api
            .mutation("upgradeClient", { id: child.id })
            .then(_ => {
              this.$router.push(`/contacts/clients/${child.id}`)
              App.flashy(this.$t("detailView.childrenDelSuccess"))
            })
            .catch(this.$axios.handleError)
        }
      )
    },
  },
  computed: {
    isReadOnly() {
      return coerceBooleanAttribute(this.field.readonly)
    },
    clientDetailViewGroups(): DetailViewGroup[] {
      const customDetailViewGroups = this.$db.shopData.childrenDetailViewGroups
      if (customDetailViewGroups.length > 0) return customDetailViewGroups
      return DEFAULT_CHILDREN_FIELDS
    },
    formFields(): Record<string, FormField> {
      return Object.fromEntries(
        Object.entries(FormFields).map(([key, value]) => {
          let translated = { ...value, label: this.$t(`clients.formFields.${snakeCase(value.filterName || key)}`) }
          if (!!value.options && Array.isArray(value.options)) {
            translated.options = value.options.map(o =>
              o.translate ? { id: o.id, name: this.$t(`clients.formFieldOptions.${o.name}`) } : o
            ) as any
          }
          return [key, translated]
        })
      ) as Record<string, FormField>
    },
    groups(): Group[] {
      return detailViewForm(
        this.formFields,
        this.clientDetailViewGroups,
        false,
        this.$db,
        this.$db.shopData.customFieldGroupsForClients
      )
    },
  },
})
</script>
