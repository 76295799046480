<template>
  <dv-row :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`" :hint="field.hint">
    <translation-dialog
      :locale="selectedLocale"
      :translations="record.translations"
      :resource-id="record.id"
      @close="selectedLocale = null"
      @complete="languageSaved"
    />
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'w-100',
      ]"
    >
      <nice-select
        :disabled="field.readonly"
        v-model="selectedLocale"
        :options="supportedLanguages"
        :placeholder="$t('detailView.translations.add')"
        size="small"
      />
    </div>
  </dv-row>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import TranslationDialog from "@/components/project/TranslationDialog.vue"
import { languagesMapping } from "@/config/property"

export default defineComponent({
  props: ["record", "field"],
  components: {
    TranslationDialog,
  },
  data() {
    return {
      selectedLocale: null as string | null,
    }
  },
  computed: {
    supportedLanguages(): Partial<typeof languagesMapping> {
      return (this.$db.shopData.supportedPropertyLocales || []).reduce((acc, locale) => {
        acc[locale] = languagesMapping[locale]
        return acc
      }, {})
    },
  },
  methods: {
    languageSaved(savedTranslation) {
      this.record.translations = this.record.translations
        .filter(t => t.locale != savedTranslation.locale)
        .concat([savedTranslation])
      this.selectedLocale = null
    },
  },
  mounted() {},
})
</script>
<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
