<template>
  <nice-select v-model="record[field.fieldName]" :placeholder="$t('client.queries.form.non')" :options="options" />
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"

export default defineComponent({
  props: {
    record: Object as PropType<any>,
    field: Object as PropType<any>,
  },
  computed: {
    options() {
      const isCustomField = (this.field as any).fieldName.startsWith("cf_")
      return [
        { name: this.$t("client.queries.form.y"), id: isCustomField ? true : "true" },
        { name: this.$t("client.queries.form.n"), id: isCustomField ? false : "false" },
      ]
    },
  },
})
</script>
