<template>
  <form-dialog
    :visible="visible"
    @update:visible="updateVisible"
    :submit-func="submit"
    @complete="complete"
    :title="title"
    :append-to-body="true"
  >
    <template v-if="editable">
      <form-section>
        <form-row :title="$t('detailView.translations.title')" direction="column">
          <nice-input size="small" v-model="editable.title" />
        </form-row>
        <form-row :title="$t('detailView.translations.subheadline')" direction="column">
          <nice-input size="small" v-model="editable.subHeadline" />
        </form-row>
        <form-row :title="$t('detailView.translations.tagline')" direction="column">
          <nice-input size="small" v-model="editable.tagline" />
        </form-row>
      </form-section>
      <form-section>
        <form-row :title="$t('detailView.translations.courtage')" direction="column">
          <nice-input size="small" v-model="editable.courtage" />
        </form-row>
        <form-row :title="$t('detailView.translations.courtageNote')" direction="column">
          <nice-input type="textarea" v-model="editable.courtageNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
      </form-section>
      <form-section>
        <form-row :title="$t('properties.formFields.description_note')" direction="column">
          <nice-input type="textarea" v-model="editable.descriptionNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
        <form-row :title="$t('properties.formFields.location_note')" direction="column">
          <nice-input type="textarea" v-model="editable.locationNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
        <form-row :title="$t('properties.formFields.furnishing_note')" direction="column">
          <nice-input type="textarea" v-model="editable.furnishingNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
      </form-section>
      <form-section>
        <form-row :title="$t('properties.formFields.long_description_note')" direction="column">
          <rich-textarea v-model="editable.longDescriptionNote" />
        </form-row>
        <form-row :title="$t('properties.formFields.long_location_note')" direction="column">
          <rich-textarea v-model="editable.longLocationNote" />
        </form-row>
        <form-row :title="$t('properties.formFields.long_furnishing_note')" direction="column">
          <rich-textarea v-model="editable.longFurnishingNote" />
        </form-row>
      </form-section>
    </template>
  </form-dialog>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { ProjectTranslation } from "@/interfaces"
import { languagesMapping } from "@/config/property"

const DEFAULT_TRANSLATION: ProjectTranslation = {
  locale: "de",
  courtage: "",
  courtageNote: "",
  subHeadline: "",
  tagline: "",
  title: "",
  descriptionNote: "",
  locationNote: "",
  furnishingNote: "",
  longDescriptionNote: "",
  longLocationNote: "",
  longFurnishingNote: "",
}

export default defineComponent({
  emits: ["close", "complete"],
  props: {
    locale: {
      type: String,
    },
    resourceId: {
      type: Number,
    },
    translations: {
      type: Array as PropType<ProjectTranslation[]>,
    },
  },
  data() {
    return {
      editable: null as unknown as any,
    }
  },
  watch: {
    locale(newValue: string): any {
      if (!this.locale) return (this.editable = null)

      const translation: ProjectTranslation = this.translations.find(t => t.locale == newValue) || {
        ...DEFAULT_TRANSLATION,
        locale: newValue,
      }
      this.editable = JSON.parse(JSON.stringify(translation))
    },
  },
  methods: {
    submit() {
      return this.$api.mutation("updateProject", {
        id: this.resourceId,
        locale: this.locale,
        attributes: _.omit(this.editable, "locale"),
      })
    },
    complete() {
      this.$emit("complete", this.editable)
      App.flashy(this.$t("detailView.translations.updateSuccess"))
    },
    updateVisible(visible: boolean) {
      if (!visible) this.$emit("close")
    },
  },
  computed: {
    title(): any {
      return this.$t("detailView.translations.formTitle", { lang: languagesMapping[this.locale] })
    },
    visible(): boolean {
      return !!this.locale
    },
  },
})
</script>
