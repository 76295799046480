<template>
  <router-link class="ritem" :to="href || '#'" @click="$emit('click', $event)">
    <slot>
      <span>
        {{ title }}
        <span class="text-muted ml-1">{{ desc }}</span>
      </span>
    </slot>
    <div>
      <span class="ritem-delete" @click.stop.prevent="$emit('edit')" :title="$t('detailView.edit')">
        <fa-icon name="pencil" />
      </span>
      <span
        class="ritem-delete"
        @click.stop.prevent="$emit('remove')"
        :title="deleteTitle || $t('detailView.relationship.delete')"
      >
        <fa-icon name="times" class="text-danger transform scale-125" />
      </span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["title", "desc", "href", "deleteTitle"],
  emits: ["click", "edit", "remove"],
}
</script>

<style scoped>
.ritem {
  min-height: 28px;
  padding: 3px 6px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  margin: 0 -6px;
}
.ritem:hover {
  background: rgba(242, 241, 238, 0.95);
}
.ritem:not(:last-child) {
  margin-bottom: 0.1rem;
}
.ritem-delete {
  margin-left: 0.5rem;
  padding: 0 4px;
  visibility: hidden;
  align-self: center;
}
.ritem:hover .ritem-delete {
  visibility: visible;
}
</style>
