import * as auth from "@/router/auth"
import { createGlobalState, get, useToggle } from "@vueuse/core"
import { computed } from "vue"

const useBackdoor = createGlobalState(() => {
  const [isBackdoorOverrideEnabled, toggleBackdoorOverride] = useToggle(false)

  const isBackdoorFromCookie = computed(() => auth.isBackdoor())
  const isBackdoor = computed(() => {
    return get(isBackdoorFromCookie) && !get(isBackdoorOverrideEnabled)
  })

  return {
    toggleBackdoorOverride,
    isBackdoor,
  }
})

export default useBackdoor
