import { debounce } from "debounce"
import * as _ from "lodash"

/*
 * columns: alle möglichen Spalten die es gibt, struktur: [{ visible, name, title, width}]
 * savedColumns: die spalten, die beim broker gespeichert sind
 * returns: [{visible, name, title, width}]
 */
export const formatColumns = function (columns, savedColumns = []) {
  const columnKeys = columns.map(o => o.name)
  const lookup = _.keyBy(columns, c => c.name)
  const old = savedColumns
    .filter(sc => columnKeys.includes(sc.name))
    .map(({ name, width, visible, sort }) => {
      const column = lookup[name]
      return {
        ...column,
        name,
        width,
        visible,
        sort,
      }
    })
  const savedColumnsList = savedColumns.map(c => c.name)
  const newColumns = columns.filter(column => !savedColumnsList.includes(column.name)).map(c => ({ ...c }))
  return old.concat(newColumns)
}

/*
  Vue Komponente muss "customColumnChoices" (mögliche Spaltenoptionen) und "customColumns" (die vom broker gespeicherten Spalten) als props haben
  brokerColumnName: custom_columns_for_clients | custom_columns_for_properties
*/
export default function (brokerColumnName) {
  return {
    startOffset: null,
    emits: ["update-column-layout"],
    watch: {
      "columns.data": {
        handler: function (newValue) {
          this.storeColumnsDebounced(newValue)
          if (!this.pristine) {
            this.$emit("update-column-layout", newValue)
          } else {
            this.$emit("update-column-layout", null)
            this.pristine = false
          }
        },
        deep: true,
      },
      customColumns: {
        handler: function (val) {
          this.$set(
            this.columns,
            "data",
            formatColumns(this.customColumnChoices, val || this.$db.broker[brokerColumnName] || [])
          )
          this.pristine = !val
        },
        deep: true,
      },
    },

    data(props) {
      const savedColumns = props.customColumns || this.$db.broker[brokerColumnName] || []
      return {
        columns: {
          currentResizing: null,
          pristine: !props.customColumns,
          data: formatColumns(props.customColumnChoices, savedColumns),
          dialogVisible: false,
        },
        loadedSavedColumns: !!savedColumns && savedColumns.length > 0,
      }
    },

    methods: {
      // updateBrokerColumnsDebounced: debounce(function(newValue) {
      //   if (this.storeColumns) return this.storeColumns(newValue)
      // }, 1000),
      storeColumnsDebounced: debounce(function (newValue) {
        if (this.storeColumns) {
          this.storeColumns(newValue)
        } else if (!this.customColumns) {
          this.$db.updateBroker({ [brokerColumnName]: newValue })
        }
      }, 1000),
      // wird nicht mehr bei properties verwendet
      onColumnSortUpdate(event) {
        this.columns.data.splice(event.newIndex, 0, this.columns.data.splice(event.oldIndex, 1)[0])
      },

      columnVisibleChanged(column) {
        if (!column.visible) return

        this.$nextTick(() => {
          $(`.js-resizable[data-column='${column.name}'] .js-column-resize-grip`).on("mousedown", e => {
            this.columns.currentResizing = column
            this.$options.startOffset = $(`.js-resizable[data-column='${column.name}']`).get(0).offsetWidth - e.pageX
          })
        })
      },

      handleColumnResizing() {
        const self = this

        $(".js-resizable").each(function () {
          const th = this
          $(this)
            .find(".js-column-resize-grip")
            .on("mousedown", function (e) {
              self.columns.currentResizing = self.columns.data.find(col => col.name === $(th).data("column"))
              self.$options.startOffset = th.offsetWidth - e.pageX
            })
        })

        $(document).on("mousemove", e => {
          if (this.columns.currentResizing && this.$options.startOffset) {
            this.columns.currentResizing.width = this.$options.startOffset + e.pageX
          }
        })

        $(document).on("mouseup", e => (this.columns.currentResizing = null))
      },

      resetColumns() {
        if (this.fetchDefaultColumns) {
          this.fetchDefaultColumns().then(defaultColumns => {
            if (!defaultColumns || defaultColumns.length === 0) {
              this.columns.data = formatColumns(this.customColumnChoices)
            } else {
              this.columns.data = formatColumns(this.customColumnChoices, defaultColumns)
            }
          })
        } else {
          this.columns.data = formatColumns(this.customColumnChoices)
        }
      },
    },

    computed: {
      userColumns() {
        return this.columns.data.filter(column => column.visible)
      },
    },

    mounted() {
      this.handleColumnResizing()

      if (this.fetchDefaultColumns && !this.loadedSavedColumns) {
        this.fetchDefaultColumns().then(defaultColumns => {
          if (!defaultColumns || defaultColumns.length === 0) return
          this.columns.data = formatColumns(this.customColumnChoices, defaultColumns)
        })
      }
    },
  }
}
