<template>
  <form-dialog
    :visible="visible"
    :name="$t('commissionSplits.dialog.title')"
    :id="edit ? edit.id : undefined"
    append-to-body
    @close="$emit('update:visible', false)"
    :saving="submitting"
    @submit="submit"
    :autofocus="!edit"
  >
    <form-section v-if="visible">
      <form-row :title="$t('commissionSplits.beneficiary')">
        <div class="w-full">
          <commission-split-broker-select
            fixed-position
            v-model="item.brokerId"
            @change="item.clientId = null"
            :disabled="Boolean(item.clientId)"
          />
          <nice-divider>{{ $t("detailView.or") }}</nice-divider>
          <client-select
            fixed-position
            v-model="item.clientId"
            @change="item.brokerId = null"
            :disabled="Boolean(item.brokerId)"
            fetch-type="CommissionSplitClients"
          />
        </div>
      </form-row>
      <form-row :title="$t('commissionSplits.name')">
        <nice-select fixed-position v-model="item.name" :options="commissionSplitNames" />
      </form-row>
      <form-row :title="$t('commissionSplits.splitPosition')">
        <nice-radio-group
          v-model="item.splitPosition"
          :options="[
            { id: 'pre', name: $t('commissionSplits.splitPositions.pre') },
            { id: null, name: $t('commissionSplits.splitPositions.split') },
            { id: 'post', name: $t('commissionSplits.splitPositions.post') },
          ]"
        />
      </form-row>
      <form-row :title="$t('commissionSplits.value')">
        <div>
          <div class="flex items-center">
            <nice-input size="small" v-model="item.value" type="number" />
            <nice-radio-group
              v-model="item.valueType"
              class="ml-2 whitespace-nowrap"
              :options="[
                { id: 'percent', name: `${$t('commissionSplits.valueTypes.percent')} (%)` },
                { id: 'fixed', name: `${$t('commissionSplits.valueTypes.fixed')} (${currency})` },
              ]"
            />
          </div>
          <footer v-if="calculatedValue" class="text-sm text-gray-600 font-mono pl-1 mt-1">
            ~ {{ $customFilters.numberToCurrency(calculatedValue, currency) }}
          </footer>
        </div>
      </form-row>
      <form-row :title="$t('commissionSplits.actor')">
        <nice-radio-group v-model="item.actor" :options="radioOptions" />
      </form-row>
      <form-row :title="$t('commissionSplits.representing')">
        <nice-select fixed-position :options="$tm('commissionSplits.representings')" v-model="item.representing" />
      </form-row>
      <form-row :title="$t('commissionSplits.date')">
        <nice-date-picker format="dd.MM.yyyy" value-format="yyyy-MM-dd" fixed-position v-model="item.date" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
import { calcCommissionValue } from "@/config/property/commission-split-calc"
import { unitMapping } from "@/config/units"
import CommissionSplitBrokerSelect from "@/components/CommissionSplitBrokerSelect.vue"
import { cloneDeep } from "lodash"

const RETURN_QUERY = `{
  id name value valueType splitPosition date actor brokerId clientId representing
  client { id name } broker { name }
}`

export default {
  components: { CommissionSplitBrokerSelect },
  props: {
    initialItem: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Object,
      required: false,
    },
    record: {
      type: Object,
      required: true,
    },
  },
  watch: {
    edit() {
      this.item = this.$util.clone(this.edit || this.initialItem)
    },
  },
  data() {
    return {
      submitting: false,
      item: this.$util.clone(this.initialItem),
    }
  },
  computed: {
    currency() {
      return unitMapping[this.record.currency || this.$db.shopData.isoCurrency]
    },
    calculatedValue() {
      return calcCommissionValue(this.record, this.item)
    },
    commissionSplitNames() {
      return this.$db.shopData.commissionSplitNames.map(name => ({ name: name, id: name }))
    },
    radioOptions() {
      const options = [
        { id: "buyer", name: this.$t("commissionSplits.actors.buyer") },
        { id: "seller", name: this.$t("commissionSplits.actors.seller") },
      ]
      if (!this.edit) options.push({ id: "both", name: this.$t("commissionSplits.actors.both") })
      return options
    },
  },
  methods: {
    submit() {
      this.submitting = true
      try {
        if (this.edit) {
          this.$api
            .update("CommissionSplit", this.edit.id, _.omit(this.item, "id", "client", "broker"), RETURN_QUERY)
            .then(commissionSplit => {
              this.$emit("create", commissionSplit)
              this.$emit("update:visible", false)
              App.flashy(this.$t("commissionSplits.dialog.editSuccess"))
            })
            .catch(this.$axios.handleError)
            .finally(_ => {
              this.submitting = false
            })
        } else {
          const create = item =>
            this.$api.create("CommissionSplit", item, RETURN_QUERY).then(split => {
              this.$emit("create", split)
              return split
            })

          let request

          if (this.item.actor === "both") {
            request = Promise.all([create({ ...this.item, actor: "buyer" }), create({ ...this.item, actor: "seller" })])
          } else {
            request = create(this.item)
          }

          request
            .then(_ => {
              this.$emit("update:visible", false)
              App.flashy(this.$t("commissionSplits.dialog.createSuccess"))
              this.item = this.$util.clone(this.initialItem)
            })
            .catch(this.$axios.handleError)
            .finally(_ => {
              this.submitting = false
            })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>
