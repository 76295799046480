<template>
  <component
    v-if="!simple"
    :is="field.componentName"
    :field="field"
    :record="record"
    :formFields="formFields"
    class="w-100"
    :data-field="field.fieldName"
  />
  <dv-row :theme="field.theme" v-else class="w-100" :data-field="field.fieldName" :field="field">
    <template v-slot:label>
      <div class="d-flex">
        <div class="mr-2">
          <span v-html="field.formField.label"></span>
          <span v-if="field.mandatory">*</span>
          <tip v-if="field.hint" :hint="field.hint" icon="info-circle" class="light" />
        </div>
        <a v-if="isURL" :href="record[field.fieldName]" target="_blank" tabindex="-1" class="light mr-1">
          <fa-icon name="external-link" />
        </a>
      </div>
    </template>
    <div
      :class="[
        {
          'mandatory-outline':
            field.mandatory &&
            !field.readonly &&
            (Array.isArray(record[field.fieldName]) ? !record[field.fieldName].length : !record[field.fieldName]) &&
            field.submitAttempt,
        },
        'w-100',
      ]"
    >
      <component :is="field.componentName" :field="field" :record="record" />
    </div>
  </dv-row>
</template>

<script>
import Container from "./Container"
import DateField from "./DateField"
import DatetimeField from "./DatetimeField"
import Dropdown from "./Dropdown"
import DbDropdown from "./DbDropdown"
import EmailList from "./EmailList"
import List from "./List"
import NumberField from "./NumberField"
import SliderField from "./SliderField"
import ExchangeField from "./ExchangeField"
import BooleanField from "./BooleanField"
import StringField from "./StringField"
import TextField from "./TextField"
import AddressFields from "./AddressFields"
import NameFields from "./NameFields"
import RelationshipField from "./RelationshipField"
import ChildrenField from "./ChildrenField"
import LinkField from "./LinkField"
import PhoneField from "./PhoneField"
import PhotoField from "./PhotoField"
import PhoneNumbersField from "./PhoneNumbersField"
import OwnerField from "./OwnerField"
import RichtextField from "./RichtextField"
import RangeField from "./RangeField"
import YesNoField from "./YesNoField"
import ClientAge from "./client/Age"
import PropertyAddressFields from "./property/AddressFields"
import PropertyCategory from "./property/Category"
import PropertyPortalExport from "./property/PortalExport"
import PropertyCommission from "./property/Commission"
import PropertyEnergyCertificate from "./property/EnergyCertificate"
import PropertyTranslations from "./property/Translations"
import PropertyCommissionSplits from "./property/CommissionSplits"
import PropertyRelationshipField from "./property/RelationshipField"
import ProjectTranslations from "./project/Translations"
import SavedQueryCategories from "./saved-query/Categories"
import SavedQueryLocations from "./saved-query/Locations"
import SavedQueryCities from "./saved-query/Cities"
import SavedQueryGeometry from "./saved-query/Geometry"
import ClientDropdown from "./client/Dropdown"
import PropertyDropdown from "./property/Dropdown"

const SIMPLE_FIELDS = [
  "StringField",
  "string-field",
  "boolean-field",
  "number-field",
  "slider-field",
  "date-field",
  "datetime-field",
  "dropdown",
  "tags",
  "tags-by-category",
  "dbDropdown",
  "list",
  "range-field",
  "yes-no-field",
  "saved-query-categories",
  "saved-query-locations",
  "saved-query-cities",
  "client-dropdown",
  "property-dropdown",
  "client-age",
]

export default {
  props: ["record", "field", "formFields"],

  computed: {
    simple() {
      return SIMPLE_FIELDS.includes(this.field.componentName)
    },
    isURL() {
      return (
        ["StringField", "string-field"].includes(this.field.componentName) &&
        this.record[this.field.fieldName] &&
        /^[a-z]+:\/\/.../.test(this.record[this.field.fieldName])
      )
    },
  },

  components: {
    Container,
    StringField,
    TextField,
    NumberField,
    SliderField,
    ExchangeField,
    BooleanField,
    EmailList,
    List,
    DateField,
    DatetimeField,
    AddressFields,
    NameFields,
    RelationshipField,
    ChildrenField,
    LinkField,
    PhoneField,
    PhotoField,
    PhoneNumbersField,
    Dropdown,
    RichtextField,
    ClientAge,
    PropertyAddressFields,
    PropertyCategory,
    PropertyPortalExport,
    PropertyCommission,
    PropertyEnergyCertificate,
    PropertyTranslations,
    PropertyCommissionSplits,
    PropertyRelationshipField,
    ProjectTranslations,
    OwnerField,
    DbDropdown,
    RangeField,
    YesNoField,
    SavedQueryCategories,
    SavedQueryLocations,
    SavedQueryCities,
    SavedQueryGeometry,
    ClientDropdown,
    PropertyDropdown,
  },
}
</script>

<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
