<template>
  <div>
    <dv-row :label="portal.name">
      <template v-slot:label>
        <span>{{ portal.name }}</span>
        <template v-if="portal.format == 'IS24' && publishedAt && record.scout_id">
          <a
            :href="`https://www.immobilienscout24.de/expose/${record.scout_id}`"
            :title="$t('detailView.portalExport.link')"
            class="text-gray-600 ml-1"
            target="_blank"
          >
            <fa-icon name="external-link" />
          </a>
          <tooltip v-if="record.local_hero_booking" content="ImmoScout24 Highlight des Tages">
            <tip
              icon="clock"
              class="text-green-600 ml-2"
              :content="
                $t('localHero.successHint', { date: $customFilters.datetime(record.local_hero_booking.endsAt) })
              "
            />
          </tooltip>
        </template>
        <template v-if="portal.format == 'FACEBOOK' || portal.format == 'INSTAGRAM'">
          <a :href="`${portal.accountUrl}`" class="text-gray-600 ml-1" target="_blank">
            <fa-icon name="external-link" />
          </a>
        </template>
      </template>
      <div class="w-100 flex items-center">
        <div>
          <nice-tag v-if="deactivated" color="yellow">{{ $t("detailView.portalExport.deactivated") }}</nice-tag>
          <p v-if="publishedAt">{{ $customFilters.datetime(publishedAt) }}</p>
        </div>
        <hover-actions v-if="$db.broker.canExportToPortals" :always-visible="true" right="0px">
          <hover-action
            :disabled="disabled || exporting"
            v-if="hasPreview && (isDevBackdoor || ($db.featureActive('publishing_data_preview') && $db.broker.admin))"
            @click="previewExport()"
          >
            <fa-icon class="text-blue-700" name="search" :title="$t('detailView.portalExport.preview')" />
          </hover-action>
          <tooltip
            v-if="portal.format == 'IDEALISTA' && publishing"
            :content="$t('detailView.portalExport.showIdealistaData')"
          >
            <hover-action @click="showIdealistaData = !showIdealistaData" icon="brackets-curly">
              <idealista-preview v-if="showIdealistaData" :publishing="publishing" @close="showIdealistaData = false" />
            </hover-action>
          </tooltip>
          <hover-action
            @click="exportProperty('ONLINE')"
            v-if="!publishedAt || (portal.format != 'FACEBOOK' && portal.format != 'INSTAGRAM')"
            :disabled="record.local_hero_booking && portal.format != 'IS24' && portal.popularWebsite"
          >
            <small v-if="deactivated" class="text-green-600">
              {{ $t("detailView.portalExport.updateAndReactivate") }}
            </small>
            <small v-else class="text-green-600">
              {{ publishedAt ? $t("detailView.portalExport.update") : $t("detailView.portalExport.publish") }}
            </small>
          </hover-action>
          <hover-action
            @click="exportProperty('ONLINE')"
            v-if="publishedAt && (portal.format == 'FACEBOOK' || portal.format == 'INSTAGRAM')"
          >
            <small class="text-blue-600">
              {{ $t("detailView.portalExport.republish") }}
            </small>
          </hover-action>
          <hover-action
            v-if="publishedAt && (portal.format == 'FACEBOOK' || portal.format == 'INSTAGRAM')"
            :disabled="!publishings || !publishings.length"
            @click="$emit('show-publishings-modal', portal.id)"
          >
            <small :class="!publishings || !publishings.length ? 'text-gray-400' : 'text-blue-700'">
              <fa-icon name="list-ul" :title="$t('detailView.portalExport.seePublishings')" />
              {{ (publishings && publishings.length) || 0 }}
            </small>
          </hover-action>
          <hover-action v-if="canDeactivate" @click="exportProperty('OFFLINE')">
            <small class="text-yellow-500">{{ $t("detailView.portalExport.deactivate") }}</small>
          </hover-action>
          <hover-action v-if="canDelete" @click="exportProperty('DELETE')">
            <small class="text-red-700">{{ $t("detailView.portalExport.delete") }}</small>
          </hover-action>
        </hover-actions>
      </div>
    </dv-row>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import useEnvironment from "@/plugins/use-environment"
import { defineAsyncComponent, defineComponent } from "vue"

export default defineComponent({
  components: { IdealistaPreview: defineAsyncComponent(() => import("./IdealistaPreview.vue")) },

  data() {
    return {
      showIdealistaData: false,
    }
  },
  setup() {
    const { isDevBackdoor } = useEnvironment()
    return { isDevBackdoor }
  },
  props: ["record", "portal", "exporting", "localHeroAvailable", "localHeroCurrentlyBooked", "disabled"],

  computed: {
    publishing(): any {
      return this.record.publishings.find(p => p.portalId == this.portal.id)
    },
    publishings(): any {
      return this.record.publishings.filter(p => p.portalId == this.portal.id)
    },
    publishedAt(): string | null {
      return this.publishing ? this.publishing.publishDate : null
    },
    publishedPortalIds(): number[] {
      return this.record.publishings.map(p => p.portalId)
    },
    publishedPortals(): any[] {
      return this.$db.shopData.connections.filter(p => this.publishedPortalIds.includes(p.id))
    },
    deactivated(): boolean {
      if (this.publishing == null) return false
      return this.publishing.publishDate == null
    },
    canDeactivate(): boolean {
      if (this.portal.format == "IDEALISTA") return this.publishedAt != null

      return this.portal.format == "IS24" && this.publishedPortals.filter(p => p.parentConnectionId).length > 0
    },
    canDelete(): boolean {
      return this.publishedAt != null && !["FACEBOOK", "INSTAGRAM", "IDEALISTA"].includes(this.portal.format)
    },
    hasPreview(): boolean {
      return ["OPENIMMO", "XML2U", "IDX", "IDEALISTA"].includes(this.portal.format)
    },
  },

  methods: {
    previewExport() {
      window.open(
        `/export/publish/preview?portal_id=${this.portal.id}&property_ids[]=${this.record.id}&active=true`,
        "_blank"
      )
    },
    exportProperty(modus: "ONLINE" | "OFFLINE" | "DELETE") {
      if (this.localHeroAvailable && this.portal.format == "IS24" && modus == "ONLINE")
        return this.$emit("show-local-hero")

      this.$api
        .mutation("exportProperties", {
          propertyIds: [this.record.id],
          portalIds: [this.portal.id],
          modus,
          localHeroCurrentlyBooked: this.localHeroCurrentlyBooked,
        })
        .then(_ => {
          this.$emit("export-success")
          App.flashy(this.$t("detailView.portalExport.invidualExportSuccess", { name: this.portal.name }))
        })
        .catch(this.$axios.handleError)
    },
  },
})
</script>
