<template>
  <li class="formRow" :class="{ 'formRow--column': this.direction == 'column' }" :disabled="disabled || undefined">
    <div
      v-if="title || $slots.label"
      class="formRow-label formRow-word-break"
      :class="{ 'pt-2': this.direction == 'row' }"
    >
      <slot name="label">
        <label class="m-0 formRow-word-break" :class="{ 'font-bold': titleBold }" v-text="title"></label>
        <tip v-if="hint" :hint="hint" class="ml-1" />
      </slot>
    </div>
    <div class="formRow-body" :style="{ display: inline ? 'flex' : 'block' }" :class="{ 'mt-2': !inline }">
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    hint: {
      type: String,
    },
    direction: {
      type: String,
      default: "row",
    },
    titleBold: {
      type: Boolean,
      default: false,
    },
    stopHintClickPropagation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inline() {
      return this.direction == "row"
    },
  },
}
</script>

<style scoped>
.formRow {
  position: relative;
  min-height: 32px;
  margin-bottom: 10px;
  display: flex;
}
.formRow--column {
  flex-direction: column;
}
.formRow-label {
  flex: 1;
  width: initial;
}
.formRow-label label:first-letter {
  text-transform: capitalize;
}
.formRow-body {
  flex: 1;
  display: flex;
  align-items: center;
}

.formRow-word-break {
  width: 99%;
  word-break: break-word;
}

/* z.B. in Suchprofil Formular */
.app-panel-body-inner .formRow-body,
.task-inner .formRow-body {
  flex: 2;
}

@media screen and (max-width: 760px) {
  .formRow {
    flex-direction: column;
  }
}
</style>
