<template>
  <div class="flex flex-col">
    <dv-row :theme="field.theme" class="w-100" :data-field="field.fieldName" :field="field">
      <template v-slot:label>
        <div class="d-flex">
          <div class="mr-2">
            <span v-html="field.formField.label"></span>
            <span v-if="field.mandatory">*</span>
            <tip v-if="field.hint" :hint="field.hint" icon="info-circle" class="light" />
          </div>
        </div>
      </template>
      <input
        type="text"
        v-model="displayValue"
        @blur="isInputActive = false"
        @focus="isInputActive = true"
        :readonly="isReadOnly"
        :class="{ readonly: isReadOnly }"
        :tabindex="isReadOnly ? -1 : ''"
        class="dv-input"
        :style="!displayValue && `background-color: ${field.color}`"
      />
    </dv-row>
    <template v-if="displayValue && record.currency">
      <dv-row
        class="w-100"
        v-for="item in $db.shopData.selectedCurrencies.filter(c => c != record.currency)"
        :key="item"
      >
        <template v-slot:label>
          <div class="d-flex">
            <div class="mr-2">
              <span></span>
            </div>
          </div>
        </template>
        <tooltip placement="left" :content="'Rate: ' + $customFilters.prettyNumber(exchangeRate[item])">
          <span class="text-xs text-gray-600">
            {{ item }} ~
            {{ $customFilters.numberToCurrency(exchangeRate[item] * value, unitMapping[item], true) }}
          </span>
        </tooltip>
      </dv-row>
    </template>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { formatNumberWithUnit } from "@/config/number-filters"
import evaluator from "@/config/script-evaluator"
import { debounce } from "@/utils"
import { Field } from "@/interfaces"
import { unitMapping } from "@/config/units"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

export default defineComponent({
  props: {
    record: {
      type: Object as PropType<any>,
      required: true,
    },
    field: { type: Object as PropType<Field>, required: true },
  },
  data() {
    return {
      isInputActive: false,
      unitMapping,
    }
  },
  computed: {
    value(): number | null {
      return this.record[this.field.fieldName]
    },
    unit(): string | undefined {
      return this.field.formField.unit
    },
    formattedUnit(): string | undefined {
      return unitMapping[this.record.currency] || (this.unit == "currency" ? this.$db.shopData.currency : this.unit)
    },
    displayValue: {
      get(): any {
        if (this.unit == "year") return this.value

        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          if (this.value || this.value == "0") return String(this.value).replace(".", ",")
          return
        } else {
          // User is not modifying now. Format display value for user interface
          if (isNaN(this.value)) return this.value
          return formatNumberWithUnit(this.value, this.formattedUnit, true, this.precision)
        }
      },
      set(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input)
        let newValue: number | null = parseFloat(
          modifiedValue
            .replace(/\./g, "")
            .replace(/,/g, ".")
            .replace(/[^\d\.\-]/g, "")
        )
        if (!newValue && newValue != 0) newValue = null
        // Ensure that it is not NaN
        if (newValue && isNaN(newValue)) newValue = 0

        // set, weil das Feld im record evtl. noch nicht existiert (vor allem bei Custom Feldern)
        this.record[this.field.fieldName] = newValue
      },
    } as any,
    precision(): number {
      return this.field.formField.precision || 2
    },
    isReadOnly() {
      return coerceBooleanAttribute(this.field.readonly || !!this.field.formField.formula)
    },
    exchangeRate(): any {
      return (this.$db.shopData.exchangeRates && this.$db.shopData.exchangeRates[this.record.currency]) || {}
    },
  },
  methods: {
    watchRecord() {
      const formula = this.field.formField.formula
      if (!formula) return

      this.$watch(
        "record",
        debounce(() => {
          evaluator
            .evalAsync(formula, this.record)
            .then(result => {
              // console.log("result", result, "for", this.field.fieldName)
              if (typeof result == "number") {
                if (isFinite(result)) {
                  result = parseFloat(result.toFixed(this.precision))
                  this.record[this.field.fieldName] = result
                } else {
                  // result is NaN or Infinity
                  this.record[this.field.fieldName] = null
                }
              } else {
                App.alert(this.$t("detailView.numberAlert", { label: this.field.formField.label, result: result }))
              }
            })
            .catch(reason => console.log(reason, this.field.fieldName))
        }),
        { deep: true }
      )
    },
  },
  mounted() {
    setTimeout(_ => {
      // delay watching so it doesn't fire immediately
      this.watchRecord()
    }, 100)
  },
})
</script>

<style scoped>
.readonly {
  pointer-events: none;
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
</style>
