<template>
  <div class="relative w-full rounded">
    <nice-input
      type="text"
      v-model="record[field.fieldName]"
      :readonly="field.readonly"
      v-bind="field.formField.additionalParams && field.formField.additionalParams(field, record[field.fieldName])"
      :class="{
        'has-char-counter': field.formField.limit,
        'disabled-visibility-string': field.readonly,
      }"
      :style="!record[field.fieldName] && `background-color: ${field.color}ff`"
      :data-value-missing="!record[field.fieldName]"
      class="rounded"
      :placeholder="field.containerPlaceholder"
    />
    <char-counter
      v-if="field.formField.limit"
      :count="record[field.fieldName] ? record[field.fieldName].length : 0"
      :total="field.formField.limit"
    />
  </div>
</template>

<script>
export default {
  props: ["record", "field"],
}
</script>

<style>
.has-char-counter .el-input__inner {
  padding-right: 50px !important;
}
.disabled-visibility-string .el-input__inner {
  opacity: 0.67;
}
</style>
