<template>
  <dv-row :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`" :hint="field.hint" :field="field">
    <translation-dialog
      :locale="selectedLocale"
      :translations="record.translations"
      :property-id="record.id"
      @close="selectedLocale = null"
      @complete="languageSaved"
    />
    <div class="flex flex-col w-100">
      <connection-item
        v-for="lang in $db.shopData.supportedPropertyLocales"
        :key="lang"
        @edit="selectedLocale = lang"
        @click="selectedLocale = lang"
        @remove="clearTranslation(lang)"
        :deleteTitle="$t('detailView.translations.deleteBtn')"
      >
        <span class="flex items-center">
          <div
            class="w-3 h-3 rounded-full ml-1 mr-2"
            :class="activeTranslations[lang] ? 'bg-green-400' : 'bg-gray-300'"
          ></div>
          {{ languagesMapping[lang] }}
        </span>
      </connection-item>
    </div>
  </dv-row>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import TranslationDialog from "@/components/property/TranslationDialog.vue"
import { languagesMapping } from "@/config/property"
import ConnectionItem from "../ConnectionItem.vue"

const DEFAULT_TRANSLATION = {
  locale: null,
  courtage: null,
  courtageNote: null,
  deposit: null,
  freeFrom: null,
  title: null,
  descriptionNote: null,
  locationNote: null,
  furnishingNote: null,
  otherNote: null,
  longDescriptionNote: null,
  longLocationNote: null,
  longFurnishingNote: null,
  longOtherNote: null,
}

export default defineComponent({
  props: ["record", "field"],
  components: {
    TranslationDialog,
    ConnectionItem,
  },
  data() {
    return {
      selectedLocale: null as string | null,
      languagesMapping,
    }
  },
  computed: {
    supportedLanguages(): Partial<typeof languagesMapping> {
      return (this.$db.shopData.supportedPropertyLocales || []).reduce((acc, locale) => {
        acc[locale] = languagesMapping[locale]
        return acc
      }, {})
    },
    activeTranslations(): any {
      return this.record.translations.reduce((a, v) => {
        const { locale, ...rest } = v
        return { ...a, [v.locale]: !Object.values(rest).every(x => x === null || x === "") }
      }, {})
    },
  },
  methods: {
    languageSaved(savedTranslation) {
      this.record.skipDirty = true
      this.record.updated_at = moment().format()
      this.record.translations = this.record.translations
        .filter(t => t.locale != savedTranslation.locale)
        .concat([savedTranslation])
      this.selectedLocale = null
    },
    clearTranslation(lang) {
      this.$warn(
        {
          title: this.$t("detailView.translations.delete.title", { locale: languagesMapping[lang] }),
          desc: this.$t("detailView.translations.delete.desc"),
          confirmButtonText: this.$t("detailView.translations.delete.submit"),
          redButton: true,
        },
        () => {
          this.$api
            .mutation("deleteTranslations", {
              id: this.record.id,
              locale: lang,
            })
            .then(() => {
              this.record.skipDirty = true
              this.record.updated_at = (window as any).moment().format()
              const clearedTranslation = [DEFAULT_TRANSLATION].map(t => ({ ...t, locale: lang }))
              this.record.translations = this.record.translations.map(
                t => clearedTranslation.find(c => c.locale === t.locale) || t
              )
              App.flashy(this.$t("detailView.translations.delete.success", { locale: languagesMapping[lang] }))
            })
            .catch(this.$axios.handleError)
        }
      )
    },
  },
  mounted() {},
})
</script>

<style>
.disabled-visibility-translations > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.55 !important;
}

.disabled-visibility-translations > .el-input > span {
  display: none !important;
}

.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
