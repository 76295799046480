import { computed } from "vue"
import useCore from "./use-core"

const useShop = () => {
  const { db } = useCore()

  const currency = computed(() => db.shopData?.currency)
  const isReshare = computed(() => !db.shopData?.enterprise)
  const isEnterprise = computed(() => db.shopData?.enterprise)
  const isSpanish = computed(() => db.shopData?.internationalCountry == "spain")

  return {
    currency,
    isReshare,
    isEnterprise,
    isSpanish,
  }
}

export default useShop
